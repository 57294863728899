import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { AlertController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import {RestService} from "../../services/rest.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    public nav: NavigationService,
    public alertController: AlertController,
    public auth: AuthService,
    private rest: RestService,
    private http: HttpClient
  ) { }

  canShow: boolean = false;

  chartDataProgression: any = {
    labels: [],
    datasets: []
  }

  isHistoricalReady: boolean = false;
  isHistoricalDateReady: boolean = false;

  chartDataHistorical: any = {
    labels: [],
    datasets: []
  }

  chartDataHistoricalDate: any = {
    labels: [],
    datasets: []
  }

  regChartOptions = {
      title: {
        display: false,
      },
      legend: {
        position: 'bottom',
      },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  levelBreakdownData = {
    labels: [],
    datasets: []
  }



  rawAppData: any = {};
  regProgressChartIsReady: boolean = false;
  isStatsReady: boolean = false;


  ngOnInit() {
    if(this.auth.loginChecked && !this.auth.loggedIn) {
      this.router.navigate(['/login']);



    }else{
      this.canShow = true;
      this.initChartData()
    }
  }

  initChartData() {
    this.rest.getResults('functions/dashboard/statistics/')
      .subscribe(
        {
          next: (res) => {
            this.rawAppData = res;

            this.isStatsReady = true;

            let tempLabels = [];

            let barSet = {
              type: 'bar',
              label: 'Per-Day Registrants',
              data: [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)'
            }

            let lineSet = {
              type: 'line',
              label: 'Rolling Total',
              data: [],
              fill: false,
              borderColor: 'rgb(54, 162, 235)'
            }

            for (let i = 0; i < this.rawAppData.registrationTimeline.length; i++) {
              tempLabels.push(this.rawAppData.registrationTimeline[i][0]);
              barSet.data.push(this.rawAppData.registrationTimeline[i][1]);
              lineSet.data.push(this.rawAppData.registrationTimeline[i][2]);
            }

            this.chartDataProgression = {
              labels: tempLabels,
              datasets: [barSet, lineSet]
            }

            let byLevelLabels = [];
            let byLevelData = [];

            for (let i = 0; i < this.rawAppData.registrationsByLevel.length; i++) {
              byLevelLabels.push(this.rawAppData.registrationsByLevel[i][0]);
              byLevelData.push(this.rawAppData.registrationsByLevel[i][1]);
            }

            this.levelBreakdownData = {
              labels: byLevelLabels,
              datasets: [{
                data: byLevelData,
                label: 'Registration Level',
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                ]
              }]
            }
            this.regProgressChartIsReady = true;
            let historicalLabels = [];
            let historicalDateLabels = [];
            let dataset2021 = {
              label: '2021',
              data: [],
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }
            let dataset2022 = {
              label: '2022',
              data: [],
              fill: false,
              borderColor: 'rgb(153, 102, 255)',
              tension: 0.1
            }
            let dataset2023 = {
              label: '2023',
              data: [],
              fill: false,
              borderColor: 'rgb(255, 159, 64)',
              tension: 0.1
            }
            let dataset2024 = {
              label: '2024',
              data: [],
              fill: false,
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }
            let dataset2025 = {
              label: '2025',
              data: [],
              fill: false,
              borderColor: 'rgb(185,99,255)',
              tension: 0.1
            }


            let dataset2021d = {
              label: '2021',
              data: [],
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }
            let dataset2022d = {
              label: '2022',
              data: [],
              fill: false,
              borderColor: 'rgb(153, 102, 255)',
              tension: 0.1
            }
            let dataset2023d = {
              label: '2023',
              data: [],
              fill: false,
              borderColor: 'rgb(255, 159, 64)',
              tension: 0.1
            }
            let dataset2024d = {
              label: '2024',
              data: [],
              fill: false,
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }
            let dataset2025d = {
              label: '2025',
              data: [],
              fill: false,
              borderColor: 'rgb(185,99,255)',
              tension: 0.1
            }

            // this
            this.http.get('/assets/data/allPreviousYears.json').subscribe(
              {
                next: (res) => {
                  let d2021 = res[0];
                  let d2022 = res[1];
                  let d2023 = res[2];
                  let d2024 = res[3];
                  let largest_dataset_length = 0;
                  if(d2021.length > largest_dataset_length){
                    largest_dataset_length = d2021.length;
                  }
                  if(d2022.length > largest_dataset_length){
                    largest_dataset_length = d2022.length;
                  }
                  if(d2023.length > largest_dataset_length){
                    largest_dataset_length = d2023.length;
                  }
                  if(d2024.length > largest_dataset_length){
                    largest_dataset_length = d2024.length;
                  }
                  if(this.rawAppData.registrationHistorical.length > largest_dataset_length){
                    largest_dataset_length = this.rawAppData.registrationHistorical.length;
                  }
                  for (let i = 0; i < largest_dataset_length; i++) {
                    historicalLabels.push("Day "+i);
                  }
                  for (let i = 0; i < d2021.length; i++) {
                    dataset2021.data.push(d2021[i][2]);
                  }
                  for (let i = 0; i < d2022.length; i++) {
                    dataset2022.data.push(d2022[i][2]);
                  }
                  for (let i = 0; i < d2023.length; i++) {
                    dataset2023.data.push(d2023[i][2]);
                  }
                  for (let i = 0; i < d2024.length; i++) {
                    dataset2024.data.push(d2024[i][2]);
                  }
                  for (let i = 0; i < this.rawAppData.registrationHistorical.length; i++) {
                    dataset2025.data.push(this.rawAppData.registrationHistorical[i][2]);
                  }
                  this.chartDataHistorical.labels = historicalLabels;
                  this.chartDataHistorical.datasets = [dataset2021, dataset2022, dataset2023, dataset2024, dataset2025];
                  this.isHistoricalReady = true;
                }
              }
            )

            this.http.get('/assets/data/allPreviousYears-date.json').subscribe(
              {
                next: (res) => {
                  let d2021 = res[0];
                  let d2022 = res[1];
                  let d2023 = res[2];
                  let d2024 = res[3];
                  let largest_dataset_length = 0;
                  largest_dataset_length = this.rawAppData.registrationHistoricalByDate.length;
                  for (let i = 0; i < largest_dataset_length; i++) {
                    historicalDateLabels.push(this.rawAppData.registrationHistoricalByDate[i][0]);
                  }
                  for (let i = 0; i < this.rawAppData.registrationHistoricalByDate.length; i++) {
                    dataset2025d.data.push(this.rawAppData.registrationHistoricalByDate[i][2]);
                    let date_to_look_for = this.rawAppData.registrationHistoricalByDate[i][0];

                    let found2021: boolean = false;
                    let found2022: boolean = false;
                    let found2023: boolean = false;
                    let found2024: boolean = false;

                    for (let j = 0; j < d2021.length; j++) {
                      if(d2021[j][0] == date_to_look_for){
                        dataset2021d.data.push(d2021[j][2]);
                        found2021 = true;
                      }
                    }
                    for (let j = 0; j < d2022.length; j++) {
                      if(d2022[j][0] == date_to_look_for){
                        dataset2022d.data.push(d2022[j][2]);
                        found2022 = true;
                      }
                    }
                    for (let j = 0; j < d2023.length; j++) {
                      if(d2023[j][0] == date_to_look_for){
                        dataset2023d.data.push(d2023[j][2]);
                        found2023 = true;
                      }
                    }
                    for (let j = 0; j < d2024.length; j++) {
                      if(d2024[j][0] == date_to_look_for){
                        dataset2024d.data.push(d2024[j][2]);
                        found2024 = true;
                      }
                    }

                    if(!found2021){
                      dataset2021d.data.push(0);
                    }
                    if(!found2022){
                      dataset2022d.data.push(0);
                    }
                    if(!found2023){
                      dataset2023d.data.push(0);
                    }
                    if(!found2024){
                      dataset2024d.data.push(0);
                    }

                  }
                  this.chartDataHistoricalDate.labels = historicalDateLabels;
                  this.chartDataHistoricalDate.datasets = [dataset2021d, dataset2022d, dataset2023d, dataset2024d, dataset2025d];
                  this.isHistoricalDateReady = true;
                }
              }
            )

          }
        });
  }

  loadThing(url: string) {
    this.nav.navigate(url);
  }
  async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Not Available',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

}
