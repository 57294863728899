// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://app.furrydelphia.org/app/registrar/api/v1/',
  eventPk: "c16853ea-5fd4-4f97-adc2-1db50ef06abc",
  resultsAuthToken: 'ad6a4a0471dfc5d9c1bff90abb10b2bc01d758a6',
  staffApiBaseUrl: 'https://staff.furrydelphia.org/api/v1/',
  staffApiAuthToken: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
